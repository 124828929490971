import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { campaignClient } from "../../api";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Graph } from "../../Components";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { getDaysBetween2Dates, getPastMonths } from "../../helpers/utils";
import "./index.css";

type Inventory = {
  flight: number;
  dollars: number;
  voucher: number;
};

type InventoryReturn = {
  prizeQuantity: Inventory;
  totalQuantity: Inventory;
};
type DateSelectionForm = {
  selectedDate: Date;
};

interface PrizeReport extends MainDbReturnTypes.Prize {
  totalWon: number;
  fulfilled: number;
  claimed: number;
  forfeited: number;
}
function Overview() {
  const [selectedmonths, setSelectedmonths] = useState(2);
  const [filter, setFilter] = useState<DateSelectionForm>();

  const {
    handleSubmit,
    getValues,
    watch,
    control,
    formState: { errors },
  } = useForm<DateSelectionForm>({ mode: "onChange" });

  const dateWatch = watch("selectedDate");
  const handleFilter = async (dates: DateSelectionForm) => {
    setFilter(dates);
  };

  const { isLoading: isLoadingParticipants, data: participants } = useQuery(
    "getParticipants",
    async () => {
      const res: MainDbReturnTypes.Participants = await campaignClient.call("getParticipants", {});
      return res.participants;
    }
  );

  const { isLoading: isLoadingPrizes, data: prizes } = useQuery("getPrizes", async () => {
    const res: MainDbReturnTypes.Prizes = await campaignClient.call("getPrizes", {});

    return res.prizes;
  });

  const { isLoading: isLoadingWinners, data: winners } = useQuery("getPrizeWinners", async () => {
    const res: MainDbReturnTypes.PrizeWinners = await campaignClient.call("getPrizeWinners", {});

    return res.prizeWinners;
  });

  const prizeStats = useMemo(() => {
    let prizesFinal: PrizeReport[] = [];

    if (winners && prizes) {
      let filteredWinners = dateWatch
        ? winners.filter((w) => dayjs(w.creationTime).isSame(dayjs(dateWatch), "day"))
        : winners;
   
      prizes.length > 0 &&
        prizes.forEach((prize) => {
          const won = filteredWinners.filter(
            (v) => v.prizeKey === prize.prizeKey && !v.forfeitTime
          );

          const fulfilled = filteredWinners.filter(
            (v) => v.prizeKey === prize.prizeKey && v.fulfillTime
          );

          const claimed = filteredWinners.filter(
            (v) => v.prizeKey === prize.prizeKey && v.declarationAndRelease
          );

          const forfeited = filteredWinners.filter(
            (v) => v.prizeKey === prize.prizeKey && v.forfeitTime
          );

          prizesFinal.push({
            ...prize,
            totalWon: won.length,
            fulfilled: fulfilled.length,
            claimed: claimed.length,
            forfeited: forfeited.length,
          });
        });
    }

    return prizesFinal;
  }, [winners, prizes, dateWatch]);

  const stats = useMemo(() => {
    if (participants) {
      let filteredParticipants = dateWatch
        ? participants.filter((w) => dayjs(w.creationTime).isSame(dayjs(dateWatch), "day"))
        : participants;

      return {
        total: filteredParticipants.length,
        optins: filteredParticipants.filter((p) => p.metadata?.optin1).length,
      };
    }
  }, [participants, dateWatch]);

  const data = useMemo(() => {
    if (participants && participants.length > 0) {
      let filteredParticipants = dateWatch
        ? participants.filter((w) => dayjs(w.creationTime).isSame(dayjs(dateWatch), "day"))
        : participants;
      return filteredParticipants.map((participant: MainDbReturnTypes.Participant) => {
        return {
          firstName: participant.firstName,
          lastName: participant.lastName,
          email: participant.email,
          creationTime: new Date(participant.creationTime),
          updateTime: new Date(participant.updateTime),
          sessionKey: participant.sessionKey,
          terms: participant.metadata?.terms ? participant.metadata?.terms.toLocaleString() : "NA",
          optin1: participant.metadata?.optin1
            ? participant.metadata?.optin1.toLocaleString()
            : "NA",
        };
      });
    }
  }, [participants, dateWatch]);

  // const graphLabels = useMemo(() => {
  //   if (data) {
  //     if ([12, 6, 3].includes(+selectedmonths)) {
  //       const xlables = getPastMonths(+selectedmonths).reverse();
  //       const ylables = xlables.map(
  //         (m) => data.filter((p) => dayjs(p.creationTime).format("MMM YY") === m).length
  //       );
  //       return { x: xlables, y: ylables };
  //     } else {
  //       const endDate = dayjs().toISOString();
  //       let startDate = dayjs().subtract(7, "day").toISOString();
  //       if (selectedmonths === 1) {
  //         startDate = dayjs()
  //           .set("month", dayjs().get("month") - 1)
  //           .toISOString();
  //       }

  //       if (selectedmonths === 2) {
  //         startDate = dayjs().subtract(14, "day").toISOString();
  //       }
  //       const xlables = getDaysBetween2Dates(startDate, endDate).map((date) =>
  //         dayjs(date).format("MMM DD")
  //       );
  //       const ylables = xlables.map(
  //         (m) =>
  //           data.filter(
  //             (p) => dayjs(p.creationTime).format("MMM DD YYYY") === m + " " + dayjs().year()
  //           ).length
  //       );

  //       return { x: xlables, y: ylables };
  //     }
  //   }
  // }, [selectedmonths, data]);

  // const { isLoading: isLoadingInventory, data: inventory } = useQuery("getInventory", async () => {
  //   const res: InventoryReturn = await campaignClient.call("getInventory", {});
  //   return res;
  // });

  if (
    // !stats ||
    isLoadingParticipants ||
    // !data ||
    isLoadingPrizes ||
    // !prizeStats ||
    isLoadingWinners 
    //isLoadingInventory
  ) {
    return <PageLoader />;
  }

  return !isLoadingParticipants && participants && participants.length > 0 && stats ? (
    <>
      <div className="main__head">
        <h2 className="main__title">Overview</h2>
      </div>
      <div className="main__body main__body--flex main__body--flex-alt">
        <div className="boxes-info">
          <ul>
            <li>
              <div className="info-box">
                <form onSubmit={handleSubmit(handleFilter)}>
                  <div className="filter">
                    <div className="date__control">
                      <Controller
                        control={control}
                        name="selectedDate"
                        render={({ field }) => (
                          <DatePicker
                            placeholderText="Total"
                            onChange={(date: any) => field.onChange(date)}
                            selected={field.value}
                            dateFormat="yyyy-MM-dd"
                          />
                        )}
                      />
                    </div>
                  </div>
                </form>

                <p> No of entries</p>
                <h1>{stats.total}</h1>

                <p> No of optins</p>
                <h1>{stats.optins}</h1>
              </div>
            </li>

            {prizeStats &&
              prizeStats.map((v, i) => {
                return (
                  <li key={v.prizeKey}>
                    <div className="info-box">
                      <h4>{v.prizeName}</h4>
                      {/* <p>
                        {inventory?.grandPrizeInventory[i]?.inventoryCount} total{" "}
                        {v.prizeName}{" "}
                      </p> */}
                      <p>{v.totalWon} prizes won</p>
                      <p>{v.claimed - v.fulfilled} prizes claimed</p>
                      <p>{v.totalWon - v.claimed} prizes unclaimed</p>
                      <p>{v.forfeited} prizes forfeited</p>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        {/* <br />
        <div className="chart"> */}
          {/* <select
            className="form-control"
            value={selectedmonths}
            onChange={(e) => {
              setSelectedmonths(+e.currentTarget.value);
            }}>
            <option value="12">12 months</option>
            <option value="6">6 months</option>
            <option value="3">3 months</option>
            <option value="1">1 month</option>
            <option value="2">2 weeks</option>
            <option value="0">1 week</option>
          </select> */}

          {/* <div className="graph">
            {graphLabels && <Graph graphLabels={graphLabels} selectedmonths={selectedmonths} />}
          </div> */}
        {/* </div> */}
      </div>
    </>
  ) : (
    <p>Currently there are no any stats to display.</p>
  );
}

export default Overview;
