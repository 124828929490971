import { useMemo, useState } from "react";
import { useQuery } from "react-query";
import * as MainDbReturnTypes from "@sprycore/main-db-types/ReturnTypes";
import { PageLoader, Table } from "../../Components";
import { TableColumns } from "./ParticipantCol";
import { campaignClient } from "../../api";
import { CSVLink } from "react-csv";

function Participants() {
  const { isLoading: isLoadingParticipants, data: participants } = useQuery(
    "getParticipants",
    async () => {
      const res: MainDbReturnTypes.Participants = await campaignClient.call("getParticipants", {});
      return res.participants;
    }
  );

  const [filterTable, setFilterTable] = useState("");

  const data = useMemo(() => {
    if (participants && participants.length > 0) {
      return participants.map((participant: MainDbReturnTypes.Participant) => {
        return {
          firstName: participant.firstName,
          lastName: participant.lastName,
          email: participant.email,
          creationTime: new Date(participant.creationTime),
          updateTime: new Date(participant.updateTime),
          sessionKey: participant.sessionKey,
          terms: participant.metadata?.terms ? "Yes" : "No",
          optin1: participant.metadata?.optin1 ? "Yes" : "No",
          question: participant.metadata?.question
            ? participant.metadata.question.toLocaleString()
            : "NA",
        };
      });
    }
  }, [participants]);

  const reportData = useMemo(() => {
    if (data) {
      return data.map((rp) => {
        return {
          "Creation Time": rp.creationTime,
          "Last Action": rp.updateTime,
          "First Name": rp.firstName,
          "Last Name": rp.lastName,
          Email: rp.email,
          "What is WestJet for in your life?": rp.question,
          Terms: rp.terms,
          "Marketing Opt-In": rp.optin1,
        };
      });
    }
  }, [data]);

  const columns = TableColumns();

  if (isLoadingParticipants) {
    return <PageLoader />;
  }

  return !isLoadingParticipants && participants && participants.length > 0 ? (
    <>
      <div className="main__head">
        <h2 className="main__title">Participants</h2>

        <div className="main__actions">
          <div className="search search--alt">
            <div className="search__row search__row--input">
              <CSVLink
                filename={`Campaign-Participants-Report-${new Date().toLocaleDateString()}`}
                className="btn btn--medium btn--mobile-small"
                data={reportData ? reportData : ""}
                asyncOnClick={true}
                target="_blank">
                Download Report
              </CSVLink>
            </div>
          </div>
        </div>
      </div>

      <div className="main__body">
        <div className="tabs js-tabs">
          <div className="search search--alt search--medium main__search">
            <div className="search__row">
              <label htmlFor="q" className="hidden">
                Search
              </label>

              <input
                type="search"
                name="q"
                id="q"
                value={filterTable}
                placeholder="Search"
                className="search__field"
                onChange={(e) => setFilterTable(e.target.value)}></input>
              <button type="submit" className="search__btn">
                <svg className="ico-search">
                  <image xlinkHref="/assets/images/svg/ico-search.svg"></image>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="tabs__body mt-5">
        <div className="table table--alt table--tabs table--big">
          <Table
            columns={columns}
            data={data ? data : []}
            tablePageSize={15}
            filterValue={filterTable}
          />
        </div>
      </div>
    </>
  ) : (
    <p>Currently there are no any participants to display.</p>
  );
}

export default Participants;
