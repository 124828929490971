import { makeAutoObservable } from "mobx";
import { Auth } from 'aws-amplify';
import { setupApiClient } from '../api';


class AuthStore {

    user = {
        name: '',
        token: '',
        resetPassword: false
  
    };

    constructor() {

        makeAutoObservable(this);
        
        this.getAuthUser().then(user=>{
            
            setupApiClient(user.signInUserSession.idToken.jwtToken);

            this.setUserName(user.username, user.signInUserSession.idToken.jwtToken)

        }).catch(error=>{
            console.error('eror', error)
        });
    
    }

    async setup() {
        
        this.getAuthUser().then(user=>{
            
            setupApiClient(user.signInUserSession.idToken.jwtToken);
    
            this.setUserName(user.username, user.signInUserSession.idToken.jwtToken)
    
            }).catch(error=>{
                console.error('eror', error)
            });      
            
        
    }

    async login(username: string, password: string, newPassword = '') {

        try {

            const user = await Auth.signIn(username, password);           

            if (user.challengeName === 'NEW_PASSWORD_REQUIRED' && !newPassword) {

                this.setUserName(user.username, '', true);

                return;

			}
            
            if (newPassword){

                await Auth.completeNewPassword(user, newPassword);

            }
            
            setupApiClient(user.signInUserSession.idToken.jwtToken);

            this.setUserName(user.username, user.signInUserSession.idToken.jwtToken);            
            
        } catch (error) {
                  
            this.setUserName('username', '', true);

            throw error;
            
        }
    }

    async logout(){

        await Auth.signOut();

        this.setUserName('', '');       

    }

    async verifyLogin(){

        try {

            const user = await Auth.currentAuthenticatedUser();            

            this.setUserName(user.username, user.signInUserSession.idToken.jwtToken)

            //console.log('verify login', session, user)
            
        } catch (error) {
            console.error('verify login', error);
            throw error;
            
        }        

    }

    setUserName = (name:string, token:string, resetPassword=false) => {

        this.user.name = name;
    
        this.user.token = token;

        this.user.resetPassword = resetPassword;
    
    };

    getUser() {
        return this.user;
    }

    async getAuthUser(){

        try {

            const user = await Auth.currentAuthenticatedUser();

            return user;
            
        } catch (error) {

            throw error;
            
        }

    }
    

}

export default AuthStore;