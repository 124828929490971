import { Row, TableProps } from "react-table"

export const TableColumns = () => {
  const COLUMNS = [
    {
      Header: "No.",
      accessor: "colNumber",
      width: "1%",
      Cell: (TableInfo: TableProps) => {
        const nmb = TableInfo.row.index + 1
        return nmb
      },
    },
    {
      Header: "Create Time",
      sortType: "datetime",
      accessor: "creationTime",
      Cell: (TableInfo: TableProps) => {
        const dateString =
          TableInfo.data[TableInfo.row.index].creationTime.toLocaleString()
        return dateString
      },
    },
    {
      Header: "Last Action",
      accessor: "updateTime",
      sortType: (rowA: Row, rowB: Row, columnId: number) => {
        if (rowA.values[columnId] > rowB.values[columnId]) return 1
        if (rowB.values[columnId] > rowA.values[columnId]) return -1
        return 0
      },
      Cell: (TableInfo: TableProps) => {
        const dateString = TableInfo.data[TableInfo.row.index].updateTime
          ? TableInfo.data[TableInfo.row.index].updateTime.toLocaleString()
          : ""
        return dateString
      },
    },
    {
      Header: "First Name",
      accessor: "firstName",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].firstName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].firstName}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: "Last Name",
      accessor: "lastName",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].lastName ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].lastName}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: "Email Address",
      accessor: "email",
      Cell: (TableInfo: TableProps) => {
        return TableInfo.data[TableInfo.row.index].email ? (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].email}
          </a>
        ) : (
          "N/A"
        )
      },
    },
    {
      Header: "What is WestJet for in your life?",
      accessor: "question",
      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].question}
          </a>
        )
      },
    },
    {
      Header: "Opt-ins",
      accessor: "optin1",
      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].optin1}
          </a>
        )
      },
    },
    {
      Header: "Terms",
      accessor: "terms",
      Cell: (TableInfo: TableProps) => {
        return (
          <a
            className="link-more link-more--alt"
            href={`/participant/${TableInfo.data[TableInfo.row.index].sessionKey}`}>
            {TableInfo.data[TableInfo.row.index].terms}
          </a>
        )
      },
    },
  ]
  return COLUMNS
}
