import { useQuery } from "react-query";
import { PageLoader } from "../../Components";
import { campaignClient } from "../../api";

type Inventory = {
  campaignKey: string;
  giftCardTypeKey?: string;
  prizeKey?: string;
  prizePoolKey?: string;
  value: number;
  unassignedCount: number;
  assignedCount: number;
  prizeName: string;
  description: string;
  inventoryCount: number;
};

type InventoryReturn = {
  instantWinsInventory: Inventory[];
  grandPrizeInventory: Inventory[];
};

function Inventory() {
  const { isLoading: isLoadingInventory, data: inventory } = useQuery(
    "getInventory",
    async () => {
      const res: InventoryReturn = await campaignClient.call(
        "getInventory",
        {}
      );

      return res;
    }
  );

  if (isLoadingInventory) {
    return <PageLoader />;
  }

  return (
    <>
      <div className="main__body">
        {inventory && inventory.instantWinsInventory.length === 0 ? (
          ""
        ) : (
          <>
            <div className="main__head">
              <h2 className="main__title">Instant Win Prizes</h2>
            </div>
            <div className="table table--secondary table--big">
              <table>
                <colgroup>
                  <col style={{ width: "4.9%" }} />

                  <col style={{ width: "5.5%" }} />

                  <col style={{ width: "5.5%" }} />

                  <col style={{ width: "12.7%" }} />

                  <col style={{ width: "26.4%" }} />

                  <col style={{ width: "5.9%" }} />
                </colgroup>
                <thead>
                  <tr>
                    <th>Total Qty</th>

                    <th>Remaining</th>

                    <th>Awarded</th>

                    <th>Name</th>

                    <th>Description</th>

                    <th>$ Value</th>
                  </tr>
                </thead>
                <tbody>
                  {inventory &&
                    inventory.instantWinsInventory.map((v: Inventory) => (
                      <tr key={`${v.giftCardTypeKey}${v.value.toString()}`}>
                        <td>{v.unassignedCount + v.assignedCount}</td>
                        <td>{v.unassignedCount}</td>
                        <td>{v.assignedCount}</td>
                        <td>{v.prizeName}</td>
                        <td>{v.description}</td>
                        <td>{v.value}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </>
        )}

        {inventory && inventory.grandPrizeInventory.length === 0 ? (
          ""
        ) : (
          <div className="table table--secondary table--big">
            <h2 className="main__title main__title--gap">Grand prizes</h2>
            <table>
              <colgroup>
                <col style={{ width: "4.9%" }} />

                <col style={{ width: "5.5%" }} />

                <col style={{ width: "5.5%" }} />

                <col style={{ width: "12.7%" }} />

                <col style={{ width: "26.4%" }} />

                <col style={{ width: "5.9%" }} />
              </colgroup>
              <thead>
                <tr>
                  <th>Total Qty</th>

                  <th>Remaining</th>

                  <th>Awarded</th>

                  <th>Name</th>

                  <th>Description</th>

                  <th>$ Value</th>
                </tr>
              </thead>
              <tbody>
                {inventory &&
                  inventory.grandPrizeInventory.map((v: Inventory) => (
                    <tr key={`${v.prizeKey}${v.prizePoolKey}`}>
                      <td>{v.inventoryCount}</td>
                      <td>{v.inventoryCount - v.assignedCount}</td>
                      <td>{v.assignedCount}</td>
                      <td>{v.prizeName}</td>
                      <td>{v.description}</td>
                      <td>{v.value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}

export default Inventory;
